import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage = 1, mySubpage = 4 }) => {
  const questionNumber = 4
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"ONE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"How can a coach take advantage of informal time?"}
        answers={[
          "Notice and connect with girls who seem to struggle to develop positive relationships and connections with the team",
          "Rehash mistakes from past games with athletes",
          "Use the time to catch up on texts and phone calls",
        ]}
        correctAnswerNumber={0}
        answerDetail={
          "Informal time makes it easier to: focus on a smaller group of players, invest in quality 1:1 time, get to know them beyond the scope of the sport, get a sense of how they’re doing each day."
        }
        nextLink={nextLink}
      >
      </Quiz>
    </Layout>
  )
}

export default withProgress(Page, 1, 4)
